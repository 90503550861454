import Ocr from 'assets/dataroom/ocr'
import { trigger } from 'core/events'
import { get, post } from 'core/services/http-service'
import { values } from 'mobx'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import type { Space } from 'stores/files/space'
import { useMst } from 'stores/store'
import { ReactComponent as HighFive } from '../../assets/high-five.svg'
import { ReactComponent as UploadZen } from '../../assets/login/register-zen.svg'
import { ReactComponent as UploadImage } from '../../assets/upload.svg'
import { TreasyFile } from '../../stores/files/treasy-file'
import Modal from '../shared/modal'
import { type DocumentType, DragDropFilesDocumentTypesModal } from './file-type-modal'
import Tips from './tips'
import { getEcoGain } from './upload/co2-gain'
import { getFileSize } from './upload/file-size'
import { getFileMimeType, ignoredFile } from './upload/mime-types'
import { type FileWithPath, getFilesAsync, upload } from './upload/upload'

const defaultIntervalDelay = 20

interface Props {
    space?: Space
    canDrag: boolean
}

const Uploader = forwardRef<HTMLInputElement, Props>(({ space, canDrag }, ref) => {
    const { files, user, config } = useMst()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [destination, setDestination] = useState<'directory' | 'space'>('space')
    const [destinationUuid, setDestinationUuid] = useState<string>(space?.uuid)
    const [dragging, setDragging] = useState<boolean>(false)
    const dragCounter = useRef<number>(0)
    const [intervalDelay, setIntervalDelay] = useState<number | null>(null)
    const [uploading, setUploading] = useState<boolean>(false)
    const [uploadPercent, setUploadPercent] = useState<number>(0)
    const [currentFileIndex, setCurrentFileIndex] = useState<number>(0)
    const [filesToUploadCount, setFilesToUploadCount] = useState<number>(0)
    const [filesToUpload, setFilesToUpload] = useState<FileWithPath[]>([])
    const [invalidFiles, setInvalidFiles] = useState<string[]>([])
    const [status, setStatus] = useState<string>('')
    const [showNoValidFilesError, setShowNoValidFilesError] = useState<boolean>(false)
    const [onError, setOnError] = useState<number>(undefined)
    const [showNoValidSubscriptionError, setShowNoValidSubscriptionError] = useState<boolean>(false)
    const [duplicates, setDuplicates] = useState<string[]>([])
    const [action, setAction] = useState<undefined | 'rename' | 'overwrite'>()
    const [size, setSize] = useState<number>(0)
    const [origSize, setOrigSize] = useState<number>(0)
    const [co2Gain, setCo2Gain] = useState<number>(0)
    const [showCo2Gain, setShowCo2Gain] = useState<boolean>(false)
    const [showOcrModal, setShowOcrModal] = useState<boolean>(false)

    type Requested = { destination?: string; uuid?: string }
    const [latestTimeout, setLatestTimeout] = useState<number>()
    const [requestedOpen, setRequestedOpen] = useState<Requested>()

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        window.clearTimeout(latestTimeout)

        setDestination((requestedOpen?.destination as 'space' | 'directory') ?? 'space')
        setDestinationUuid(requestedOpen?.uuid ?? space?.uuid)

        if (!requestedOpen?.destination) {
            return
        }

        const timeoutId = window.setTimeout(
            (requestedOpen: Requested) => {
                const { destination, uuid } = requestedOpen

                let directoryOrSpace = null
                if (destination === 'directory') {
                    directoryOrSpace = files.recursivelyFindDirectory(uuid)
                } else if (destination === 'space') {
                    directoryOrSpace = files.recursivelyFindSpace(uuid)
                }

                if (directoryOrSpace && !config.isOpened(directoryOrSpace.uuid)) {
                    directoryOrSpace.refresh()
                    config.changeOpened(directoryOrSpace, true)

                    // TODO stop bounce when delay reached?
                    /* for (const el of Array.from(document.querySelectorAll(`[data-drop-uuid="${uuid}"]`))) {
                        el.classList.remove('animate')
                    } */
                }
            },
            2000,
            requestedOpen
        )

        setLatestTimeout(latestTimeout)

        return () => window.clearTimeout(timeoutId)
    }, [requestedOpen])

    const changeOcrStatus = async (status: 'never' | 'always' | 'manually') => {
        user.setOcrStatus(status)
        await user.update()
        setShowOcrModal(false)
    }

    const handleDragIn = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()

        if (!files.canUpload) {
            setShowNoValidSubscriptionError(true)

            return
        }

        for (const item of event.dataTransfer.items) {
            if (item.kind !== 'file') {
                return
            }
        }

        if (event.target && event.target instanceof HTMLElement) {
            let target = event.target
            let destination: string | null = null
            let uuid: string | null = null

            let guard = 0

            // eslint-disable-next-line no-constant-condition
            while (true) {
                const dataset = (target as HTMLElement).dataset
                destination = dataset['drop-type'] ?? dataset.dropType
                uuid = dataset['drop-uuid'] ?? dataset.dropUuid

                if (destination && uuid) {
                    break
                }

                target = target.parentElement
                if (target === null || guard++ > 100) {
                    break
                }
            }

            if (!destination && !uuid) {
                for (const el of Array.from(document.querySelectorAll('.drag-over'))) {
                    el.classList.remove('drag-over', 'bounce')
                }
            }

            setRequestedOpen({ destination, uuid })

            for (const el of Array.from(document.querySelectorAll('.drag-over'))) {
                el.classList.remove('drag-over', 'bounce')
            }

            if (destination && uuid && ['space', 'directory'].includes(destination) && uuid) {
                for (const el of Array.from(document.querySelectorAll(`[data-drop-uuid="${uuid}"]`))) {
                    el.classList.add('drag-over', 'bounce')
                }
            }
        }

        dragCounter.current++
        // safari does not have the length here...
        // if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
        setDragging(true)
        // }
    }

    const handleDragOut = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()

        if (!event.clientX && !event.clientY) {
            for (const el of Array.from(document.querySelectorAll('.drag-over'))) {
                el.classList.remove('drag-over', 'bounce')
            }
        }

        dragCounter.current--
        if (dragCounter.current > 0) {
            return
        }

        setDragging(false)
    }

    const handleDrag = (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
    }

    const cancelUpload = async (refresh = true) => {
        setFilesToUpload([])
        setFilesToUploadCount(0)
        setUploading(false)
        setOnError(undefined)

        if (refresh) {
            const currentSpace = space ?? values<Space>(files.spaces).find(space => space.isStack)
            await currentSpace.refresh()
        }
    }

    const uploadFiles = async (action: string): Promise<{ size: number; origSize: number; co2Gain: number }> => {
        let index = 0
        let totalSize = 0
        let totalOrigSize = 0
        let totalCo2Gain = 0

        const newFiles = filesToUpload

        const uploadedObjects: { uuid: string; spaceUuid: string; directoryUuid: string; name: string }[] = []

        for (const file of newFiles) {
            setCurrentFileIndex(index + 1)
            setUploadPercent(0)

            const treasyFile = new TreasyFile(file)
            try {
                const mimeType = getFileMimeType(file.file)
                const { size, origSize, co2Gain, name, uuid, spaceUuid, directoryUuid } = await upload(
                    mimeType,
                    treasyFile,
                    destination,
                    destinationUuid,
                    (status, filename) => setStatus(t(status, { filename })),
                    uploadPercent => setUploadPercent(uploadPercent),
                    action
                )

                uploadedObjects.push({ uuid, spaceUuid, directoryUuid, name })

                totalSize += size
                totalOrigSize += origSize
                totalCo2Gain += co2Gain
                index += 1
            } catch (error) {
                console.error(error)
                if (error.message === 'web_no_valid_subscription') {
                    await cancelUpload(false)
                    setShowNoValidSubscriptionError(true)

                    return undefined
                }
            }
        }
        if (index > 0) {
            newFiles.splice(0, index)
            setFilesToUpload(newFiles)
        }

        if (uploadedObjects.length > 0) {
            const notifyData = {
                type: 'uploaded_objects',
                data: { uploadedObjects },
            }
            await post<typeof notifyData, void>('/v1/web/notifications/event', notifyData)
        }

        trigger('uploader:uploaded_objects', { objects: uploadedObjects })

        return { size: totalSize, origSize: totalOrigSize, co2Gain: totalCo2Gain }
    }

    const startUpload = async () => {
        setUploading(true)

        setFilesToUploadCount(filesToUpload.length)
        setCurrentFileIndex(0)

        setIntervalDelay(defaultIntervalDelay * 1000)

        files.setUploading(true)

        const uploadedData = await uploadFiles(action)
        if (!uploadedData) {
            return
        }

        if (filesToUpload.length !== 0) {
            setOnError(filesToUpload.length)
            setUploading(false)

            return
        }

        const currentSpace = space ?? values<Space>(files.spaces).find(space => space.isStack)
        if (destination && destinationUuid) {
            files.markForceRefresh(destinationUuid)
        } else {
            files.markForceRefresh(currentSpace.uuid)
        }
        for (const el of Array.from(document.querySelectorAll('.drag-over'))) {
            el.classList.remove('drag-over')
        }

        files.setUploading(false)
        setUploading(false)

        const { size, origSize, co2Gain } = uploadedData
        if (size === origSize || (destination && destination === 'space')) {
            let uuid = currentSpace.uuid
            if (destination === 'space' && destinationUuid) {
                uuid = destinationUuid
            }
            navigate(`/dataroom/spaces/${uuid}`)

            return
        }
        setSize(size)
        setOrigSize(origSize)
        setCo2Gain(co2Gain)
        setShowCo2Gain(true)
    }

    const closeCo2Gain = async () => {
        setShowCo2Gain(false)

        const currentSpace = space ?? values<Space>(files.spaces).find(space => space.isStack)
        let uuid = currentSpace.uuid
        if (destination === 'space' && destinationUuid) {
            uuid = destinationUuid
        }
        navigate(`/dataroom/spaces/${uuid}`)
    }

    const restartUpload = async () => {
        setOnError(undefined)
        await startUpload()
    }

    const checkDuplicate = async (file: FileWithPath, destination: 'space' | 'directory', destinationUuid: string) => {
        const getData = {
            d: destination,
            du: destinationUuid,
            f: file.fullPath,
        }
        try {
            await get<typeof getData, { data: { success?: true } }>('/v1/web/files/exists', getData)

            return false
        } catch (e) {
            return true
        }
    }

    const validateFiles = async (uploadfiles: FileWithPath[]) => {
        const validFiles: FileWithPath[] = []
        const invalidFiles: string[] = []
        const duplicates: string[] = []

        let showOcrHelp = false

        for (const file of uploadfiles) {
            if (ignoredFile(file.file)) {
                continue
            }

            const mimeType = getFileMimeType(file.file)
            if (mimeType !== undefined) {
                validFiles.push(file)

                if (files.ocrMimeTypes.includes(mimeType)) {
                    showOcrHelp = true
                }
                const isDuplicate = await checkDuplicate(file, destination, destinationUuid)
                if (isDuplicate) {
                    duplicates.push(file.file.name)
                }
            } else if (!invalidFiles.includes(file.file.name)) {
                invalidFiles.push(file.file.name)
            }
        }

        if (invalidFiles.length !== 0) {
            setInvalidFiles(invalidFiles)
        } else if (validFiles.length === 0) {
            setShowNoValidFilesError(true)
        }

        if (validFiles.length > 0) {
            setFilesToUpload(validFiles)
            if (user.ocrStatus === 'not_configured') {
                setShowOcrModal(showOcrHelp)
            }
            if (duplicates.length > 0) {
                setDuplicates(duplicates)
            }
        }
    }

    const handleFileSubmit = (files: FileList) =>
        validateFiles(Array.from(files).map(file => ({ file, fullPath: file.name })))

    const [showTypesModal, setShowTypesModal] = useState<boolean>(false)
    const [filesToMap, setFilesToMap] = useState<FileWithPath[]>([])
    const [dragEvent, setDragEvent] = useState<DragEvent>()

    const closeTypesModal = () => {
        setShowTypesModal(false)
        setFilesToMap([])
    }

    const confirmFilesMapping = async (files: FileWithPath[]) => {
        closeTypesModal()
        await validateFiles(files)
        dragEvent?.dataTransfer.clearData()
    }

    const handleDrop = async (event: DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setDragging(false)
        setOnError(undefined)

        for (const item of event.dataTransfer.items) {
            if (item.kind !== 'file') {
                return
            }
        }

        dragCounter.current = 0
        const addedFiles: FileWithPath[] = await getFilesAsync(event.dataTransfer)

        /* if (destination === 'directory' && documentTypes.length > 0) {
            const data = await get<void, { data: { directory: SpaceDirectory } }>(
                `/v1/web/directories/${destinationUuid}`
            )
            const {
                data: { directory },
            } = data

            if (!directory) {
                return
            }

            if (directory.options.checklistDirectory) {
                setFilesToMap(addedFiles.map(file => ({ ...file, id: createId() })))
                setShowTypesModal(true)
                setDragEvent(event)
            }
        } else {
            await validateFiles(addedFiles)
        } */

        await validateFiles(addedFiles)
        event.dataTransfer.clearData()
    }

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (!canDrag) {
            return
        }
        window.addEventListener('dragenter', handleDragIn)
        window.addEventListener('dragleave', handleDragOut)
        window.addEventListener('dragover', handleDrag)
        window.addEventListener('drop', handleDrop)

        return () => {
            window.removeEventListener('dragenter', handleDragIn)
            window.removeEventListener('dragleave', handleDragOut)
            window.removeEventListener('dragover', handleDrag)
            window.removeEventListener('drop', handleDrop)
        }
    }, [canDrag, destination, destinationUuid])

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        if (
            showOcrModal ||
            onError ||
            filesToUpload.length === 0 ||
            uploading ||
            invalidFiles.length !== 0 ||
            showNoValidSubscriptionError ||
            duplicates.length !== 0
        ) {
            return
        }
        startUpload()
    }, [onError, filesToUpload, uploading, invalidFiles, showNoValidSubscriptionError, duplicates, showOcrModal])

    useBeforeunload(event => {
        if (uploading) {
            event.preventDefault()

            return t('web_uploading_change_page')
        }
    })

    const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([])
    const loadDocumentTypes = async () => {
        const data = await get<void, { data: { types: DocumentType[] } }>('/v1/web/checklist-document-types')
        const {
            data: { types },
        } = data

        setDocumentTypes(types)
    }

    useEffect(() => {
        //loadDocumentTypes()

        document.querySelector('#uploader_input').addEventListener('cancel', e => {
            trigger('uploader:uploaded_objects', { objects: [] })
        })
    }, [])

    return (
        <>
            <input
                id="uploader_input"
                type="file"
                ref={ref}
                multiple={true}
                onChange={e => handleFileSubmit(e.target.files)}
                className="hidden"
            />

            {dragging && (
                <div className="pointer-events-none fixed inset-0 z-50 flex h-screen items-center justify-center bg-black/20">
                    <div className="m-2 flex h-60 w-full flex-none flex-col items-center p-2">
                        <UploadImage />
                        <h2 className="text-4xl font-bold text-white">{t('web_dataroom_upload_drag_drop')}</h2>
                        <p className="text-lg text-white">{t('web_dataroom_upload_add_files')}</p>
                    </div>
                </div>
            )}

            {showTypesModal && (
                <DragDropFilesDocumentTypesModal
                    onClose={() => {
                        closeTypesModal()
                        dragEvent?.dataTransfer.clearData()
                    }}
                    onConfirm={confirmFilesMapping}
                    documentTypes={documentTypes}
                    filesToMap={filesToMap}
                />
            )}

            <Modal
                isOpen={uploading}
                size="1/2"
                onRequestClose={() => {}}
                overflowHidden={false}
                overlayClassName="z-50"
            >
                <UploadZen className="-mt-36" />
                <h3 className="mt-12 font-bold">
                    {t('web_uploading_files', {
                        current: currentFileIndex,
                        count: filesToUploadCount,
                    })}
                </h3>
                {status !== '' && <span className="text-sm text-regent-gray">{status}</span>}

                <div className="mt-4 flex h-6 w-full overflow-hidden rounded bg-gallery">
                    <div
                        className="flex h-6 items-center justify-center overflow-hidden bg-gradient-to-r from-yellow-orange to-christine text-sm text-white transition-all duration-150"
                        style={{ width: `${uploadPercent}%` }}
                    >
                        {uploadPercent}%
                    </div>
                </div>

                <Tips intervalDelay={intervalDelay} />
                <p className="mt-4">
                    <span
                        className="cursor-pointer text-sm font-bold text-thunder underline"
                        onClick={() => cancelUpload()}
                    >
                        {t('web_upload_cancel')}
                    </span>
                </p>
            </Modal>

            <Modal
                bottomAlignment="center"
                isOpen={showNoValidFilesError && invalidFiles.length === 0}
                onRequestClose={() => {}}
                onConfirm={() => setShowNoValidFilesError(false)}
                overlayClassName="z-50"
            >
                <h3 className="mt-12 font-bold">{t('web_uploading_files_error')}</h3>
                <p className="mt-2">{t('web_uploading_files_error_invalid_files')}</p>
            </Modal>

            <Modal
                bottomAlignment="center"
                isOpen={showCo2Gain}
                onRequestClose={() => closeCo2Gain()}
                overlayClassName="z-50"
            >
                <div className="flex flex-col items-center p-2">
                    <HighFive />
                    <h3 className="mt-12 font-bold">{t('web_congratulations')}</h3>
                    <p className="my-4">
                        <strong>{t('web_compressed_files')}</strong>
                        <br />
                        <span className="mb-4 text-sm font-bold text-regent-gray">
                            <Trans
                                i18nKey="web_compressed_files_details"
                                values={{ size: getFileSize(t, origSize - size), co2Gain: getEcoGain(t, co2Gain) }}
                            >
                                s <span className="text-bittersweet">s</span> et <span className="text-sushi">c</span>
                            </Trans>
                        </span>
                    </p>
                    <button
                        type="button"
                        className="btn white cursor-pointer text-sm font-bold"
                        onClick={() => closeCo2Gain()}
                    >
                        {t('web_continue')}
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={showOcrModal}
                onRequestClose={() => setShowOcrModal(false)}
                size="1/2"
                overlayClassName="z-50"
            >
                <div className="flex w-full flex-col items-center justify-center">
                    <Ocr className="w-20 text-christine" />
                    <h3 className="mt-12 text-xl font-bold">{t('web_ocr_new_title')}</h3>
                    <h4 className="mt-12 text-lg font-bold">{t('web_ocr_new_subtitle')}</h4>
                    <div className="prose mt-4 text-left">
                        <Trans i18nKey="web_ocr_new_desc">{t('web_ocr_new_desc')}</Trans>
                    </div>
                    <p className="mt-12 font-bold">{t('web_ocr_new_want_try')}</p>
                    <div className="flex justify-around">
                        <button
                            type="button"
                            className="btn float-left mr-4 mt-5 inline-flex cursor-pointer"
                            onClick={() => changeOcrStatus('always')}
                        >
                            {t('web_ocr_new_activate_always')}
                        </button>
                        <button
                            type="button"
                            className="btn float-left mr-4 mt-5 inline-flex cursor-pointer"
                            onClick={() => changeOcrStatus('manually')}
                        >
                            {t('web_ocr_new_activate_manually')}
                        </button>
                    </div>
                    <p className="mt-4">
                        <button
                            type="button"
                            className="cursor-pointer text-sm font-bold text-thunder underline"
                            onClick={() => changeOcrStatus('never')}
                        >
                            {t('web_ocr_new_fear_ocr')}
                        </button>
                    </p>
                </div>
            </Modal>

            <Modal
                bottomAlignment="center"
                isOpen={showNoValidSubscriptionError}
                onRequestClose={() => {}}
                onConfirm={() => setShowNoValidSubscriptionError(false)}
                overlayClassName="z-50"
            >
                <h3 className="mt-12 font-bold">{t('web_uploading_files_error', { count: 10 })}</h3>
                <p className="mt-2">{t('web_no_valid_subscription')}</p>
            </Modal>

            <Modal
                isOpen={invalidFiles.length > 0}
                onRequestClose={() => {}}
                bottomAlignment="center"
                onConfirm={() => setInvalidFiles([])}
                overlayClassName="z-50"
            >
                <h3 className="mt-12 font-bold">{t('web_uploading_invalid_files', { count: invalidFiles.length })}</h3>
                <h4 className="mt-2">{t('web_uploading_invalid_files_desc', { count: invalidFiles.length })}</h4>
                <div className="my-4 max-h-64 w-full overflow-y-auto bg-geyser p-4 shadow-inner">
                    <ul>
                        {invalidFiles.map(filename => (
                            <li key={filename}>{filename}</li>
                        ))}
                    </ul>
                </div>
            </Modal>

            <Modal
                isOpen={duplicates.length > 0}
                onRequestClose={() => {}}
                bottomAlignment="center"
                overlayClassName="z-50"
            >
                <h3 className="mt-12 font-bold">{t('web_uploading_files_duplicated')}</h3>
                <p className="mt-2">{t('web_uploading_files_rename_or_overwrite')}</p>
                <div className="my-4 max-h-64 w-full overflow-y-auto bg-geyser p-4 shadow-inner">
                    <ul>
                        {duplicates.map((duplicate, index) => (
                            <li key={index}>{duplicate}</li>
                        ))}
                    </ul>
                </div>
                <div className="inline-flex pb-4">
                    <button
                        type="button"
                        className="btn float-left mr-4 mt-5 inline-flex cursor-pointer"
                        onClick={async () => {
                            setAction('rename')
                            setDuplicates([])
                            setOnError(undefined)
                            setShowCo2Gain(true)
                        }}
                    >
                        {t('web_uploading_files_rename_button')}
                    </button>
                    <button
                        type="button"
                        className="btn ml-4 mt-5 inline-flex cursor-pointer"
                        onClick={async () => {
                            setAction('overwrite')
                            setDuplicates([])
                            setOnError(undefined)
                        }}
                    >
                        {t('web_uploading_files_overwrite_button')}
                    </button>
                </div>
            </Modal>

            <Modal
                isOpen={onError !== undefined && duplicates.length === 0}
                onRequestClose={() => {}}
                onCancel={() => cancelUpload()}
                onConfirm={() => restartUpload()}
                overlayClassName="z-50"
            >
                <h3 className="mt-12 font-bold">{t('web_uploading_files_error', { count: onError })}</h3>
                <h4 className="mt-2">{t('web_uploading_files_error_desc', { count: onError })}</h4>
                <p className="mt-2">{t('web_uploading_files_error_remaining', { count: onError })}</p>
            </Modal>
        </>
    )
})
Uploader.displayName = 'Uploader'

export default Uploader

import { S3 } from '@aws-sdk/client-s3'
import { Upload } from '@aws-sdk/lib-storage'
import { safariStream } from 'components/dataroom/upload/upload'
import { mapOrJsonOptions, mapToObject } from 'core/utils/map-to-object'
import toast from 'core/utils/toast'
import { DateTime } from 'luxon'
import { toJS } from 'mobx'
import { type Instance, applySnapshot, flow, getRoot, types } from 'mobx-state-tree'
import pako from 'pako'
import slugify from 'slugify'
import { v4 as uuidv4 } from 'uuid'
import { del, get, post, put } from '../core/services/http-service'
import { _AnyJsonValue } from './any'
import type { BrandNews } from './brands/news'
import { _Pricing } from './pricing'
import type { RootInstance } from './store'
import { type User, _User } from './users'

const _Tag = types.model('Tag', {
    uuid: '',
    name: '',
})

type ShowOption =
    | 'show_telecollecte'
    | 'show_models'
    | 'show_user_profile'
    | 'show_crisp_button'
    | 'admin_can_add_users'
    | 'secure_collaboration'

export const hasBrandAccess = (user: User | undefined, option: ShowOption): boolean => {
    if (!user || !user.hasFranchise) {
        return true
    }

    const config = mapOrJsonOptions(toJS(user.currentFranchise.brandConfig))
    const value = config[option] ?? false

    return !!value
}

export const _ChecklistStepFile = types
    .model('ChecklistStepFile', {
        uuid: '',
        name: '',
        position: 0,
        completedAt: types.optional(types.string, '', [null, undefined]),
        date: types.optional(types.string, '', [null, undefined]),
        isOcr: false,
        notConcerned: false,
        expectedFilesCount: 1,
        receivedFilesCount: 0,
        options: types.optional(_AnyJsonValue, {}, [null, undefined]),
    })
    .views(self => ({
        get completedAtDate(): DateTime | undefined {
            if (!self.completedAt || self.completedAt === '') {
                return undefined
            }

            return DateTime.fromISO(self.completedAt)
        },
        get dateDate(): DateTime | undefined {
            if (!self.date || self.date === '') {
                return undefined
            }

            return DateTime.fromISO(self.date)
        },
    }))
    .actions(self => ({
        reset: () => {
            applySnapshot(self, {})
        },

        refresh(file) {
            applySnapshot(self, file)
        },
    }))
export interface ChecklistStepFile extends Instance<typeof _ChecklistStepFile> {}

export const _ChecklistStep = types.model('ChecklistStep', {
    uuid: '',
    name: '',
    position: 0,
    completion: 0,
    files: types.array(_ChecklistStepFile),
    options: types.optional(_AnyJsonValue, {}, [null, undefined]),
})
export interface ChecklistStep extends Instance<typeof _ChecklistStep> {}

export const _Checklist = types.model('Checklist', {
    uuid: '',
    pdf: types.optional(types.string, '', [null, undefined]),
    steps: types.array(_ChecklistStep),
    readOnly: types.optional(types.boolean, false, [null, undefined]),
})
export interface Checklist extends Instance<typeof _Checklist> {}

export const _Client = types.model('Client', {
    uuid: '',
    name: '',
    partnerId: types.optional(types.string, '', [null, undefined]),
    checklist: types.optional(_Checklist, {}, [null, undefined]),
})
export interface Client extends Instance<typeof _Client> {}

export const _Franchise = types
    .model('Franchise', {
        uuid: '',
        name: '',
        brandUuid: '',
        partnerId: '',
        logo: types.optional(types.string, '', [null, undefined]),
        icon: types.optional(types.string, '', [null, undefined]),
        brandImage: types.optional(types.string, '', [null, undefined]),
        brandLogo: types.optional(types.string, '', [null, undefined]),
        viaColor: types.optional(types.string, '', [null, undefined]),
        url: types.optional(types.string, '', [null, undefined]),
        color: types.optional(types.string, '', [null, undefined]),
        role: types.optional(types.string, '', [null, undefined]),
        storage: 0,
        enabledAt: types.optional(types.string, '', [null, undefined]),
        brandConfig: types.optional(_AnyJsonValue, {}, [null, undefined]),
        users: types.array(types.late(() => _User)),
        tags: types.array(_Tag),
    })
    .views(self => ({
        get enabled(): boolean {
            return self.enabledAt !== ''
        },

        get enabledDate(): DateTime | undefined {
            return self.enabledAt !== '' ? DateTime.fromISO(self.enabledAt) : undefined
        },
    }))
    .actions(self => ({
        getConfig() {
            return mapToObject(toJS(self.brandConfig))
        },

        reset: () => {
            applySnapshot(self, {})
        },

        refresh(franchise) {
            applySnapshot(self, franchise)
        },

        assignUsers({ users }) {
            self.users = users.map(u => ({
                ...u,
                id: u.uuid,
            }))
        },
    }))
    .actions(self => ({
        loadUsers: async (q?: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.users.clear()

            const getData = {
                q: q === '' ? undefined : q,
            }

            try {
                const {
                    data: { users },
                } = await get<typeof getData, { data: { users; count } }>(
                    `/v1/bo/brands/${self.brandUuid}/franchises/${self.uuid}/users`,
                    getData
                )
                self.assignUsers({ users })
            } catch (error) {
                console.error(error)
            }
        },
    }))
export interface Franchise extends Instance<typeof _Franchise> {}

export const _Brand = types
    .model('Brand', {
        uuid: '',
        name: '',
        logo: '',
        icon: '',
        configFile: '',
        partnerId: '',
        color: types.optional(types.string, '', [null, undefined]),
        brandImage: types.optional(types.string, '', [null, undefined]),
        brandLogo: types.optional(types.string, '', [null, undefined]),
        viaColor: types.optional(types.string, '', [null, undefined]),
        role: types.optional(types.string, '', [null, undefined]),
        url: types.optional(types.string, '', [null, undefined]),
        enabledAt: types.optional(types.string, '', [null, undefined]),
        config: types.optional(_AnyJsonValue, {}, [null, undefined]),
        stacks: types.optional(types.array(types.string), [], [null, undefined]),
        userPricing: types.optional(_Pricing, {}, [null, undefined]),
        partnerPricing: types.optional(_Pricing, {}, [null, undefined]),

        hrEnabled: types.optional(types.boolean, false, [null, undefined]),
        hrFilePattern: types.optional(types.string, '', [null, undefined]),

        apiToken: '',
        apiSecret: '',

        users: types.array(types.late(() => _User)),

        franchises: types.array(_Franchise),
        totalFranchises: 0,
        editingFranchise: types.optional(_Franchise, {}),
    })
    .views(self => ({
        get enabled(): boolean {
            return self.enabledAt !== ''
        },

        get enabledDate(): DateTime | undefined {
            return self.enabledAt !== '' ? DateTime.fromISO(self.enabledAt) : undefined
        },
    }))
    .actions(self => ({
        getConfig() {
            return mapToObject(toJS(self.config))
        },

        reset: () => {
            applySnapshot(self, {})
        },

        refresh(brand) {
            applySnapshot(self, brand)
        },

        assignUsers({ users }) {
            self.users = users
        },

        assignFranchise({ franchise }) {
            self.editingFranchise = franchise
        },

        assignFranchises({ franchises, totalFranchises }) {
            self.franchises = franchises
            self.totalFranchises = totalFranchises
        },
    }))
    .actions(self => ({
        loadUsers: async (q?: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.users.clear()

            const getData = {
                q: q === '' ? undefined : q,
            }

            try {
                const {
                    data: { users },
                } = await get<typeof getData, { data: { users; count } }>(`/v1/bo/brands/${self.uuid}/users`, getData)
                self.assignUsers({ users })
            } catch (error) {
                console.error(error)
            }
        },

        duplicateFranchise: async () => {
            try {
                await post(`/v1/bo/brands/${self.uuid}/franchises/duplicate`)
                toast('success', 'web_brand_franchise_saved')
            } catch (error) {
                console.error(error)
            }
        },

        loadFranchises: async (q?: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            self.franchises.clear()
            self.totalFranchises = 0

            const getData = {
                q: q === '' ? undefined : q,
            }

            try {
                const {
                    data: { franchises, count },
                } = await get<typeof getData, { data: { franchises: Franchise[]; count: number } }>(
                    `/v1/bo/brands/${self.uuid}/franchises`,
                    getData
                )
                self.assignFranchises({ franchises, totalFranchises: count })
            } catch (error) {
                console.error(error)
            }
        },

        loadFranchise: async (id: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { franchise },
                } = await get<void, { data: { franchise: Franchise } }>(`/v1/bo/brands/${self.uuid}/franchises/${id}`)

                self.assignFranchise({ franchise })
            } catch (error) {
                console.error(error)
            }
        },

        createFranchise: async (
            name: string,
            url: string,
            logo: string,
            icon: string,
            storage: number,
            partnerId: string,
            color: string,
            viaColor: string,
            brandImage: string,
            brandLogo: string,
            tags: string[]
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                name,
                url,
                logo,
                icon,
                storage,
                partnerId,
                color,
                viaColor,
                brandImage,
                brandLogo,
                tags,
            }

            try {
                const postData = await post<typeof jsonData, { data: { franchise: Franchise } }>(
                    `/v1/bo/brands/${self.uuid}/franchises`,
                    jsonData
                )
                const {
                    data: { franchise },
                } = postData
                self.assignFranchise({ franchise })

                toast('success', 'web_brand_franchise_saved')
            } catch (error) {
                console.error(error)
            }
        },

        updateFranchise: async (
            id: string,
            name: string,
            url: string,
            logo: string,
            icon: string,
            storage: number,
            partnerId: string,
            color: string,
            viaColor: string,
            brandImage: string,
            brandLogo: string,
            tags: string[]
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = {
                name,
                url,
                logo,
                icon,
                storage,
                partnerId,
                color,
                viaColor,
                brandImage,
                brandLogo,
                tags,
            }

            try {
                const postData = await put<typeof jsonData, { data: { franchise: Franchise } }>(
                    `/v1/bo/brands/${self.uuid}/franchises/${id}`,
                    jsonData
                )
                const {
                    data: { franchise },
                } = postData
                self.assignFranchise({ franchise })

                toast('success', 'web_brand_franchise_saved')
            } catch (error) {
                console.error(error)
            }
        },

        loadWelcomeMessage: async (locale = 'fr') => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const data = await get<void, { data: { message: string } }>(
                `/v1/web/brands/welcome-message?locale=${locale}`
            )
            const {
                data: { message },
            } = data

            return message
        },

        saveWelcomeMessage: async (message: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const jsonData = { welcomeMessage: { fr: message } }

            const data = await put<typeof jsonData, { data: { message: string } }>(
                '/v1/web/brands/welcome-message',
                jsonData
            )
            const {
                data: { message: savedMessage },
            } = data

            return savedMessage
        },

        dashboardListNews: async () => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { news, count },
                } = await get<void, { data: { news: BrandNews[]; count: number } }>('/v1/web/brands/news/dashboard')

                return { news, count }
            } catch (err) {
                root.error.prepare(err)
            }
        },

        listNews: async () => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { news, count },
                } = await get<void, { data: { news: BrandNews[]; count: number } }>('/v1/web/brands/news')

                return { news, count }
            } catch (err) {
                root.error.prepare(err)
            }
        },

        loadNews: async (id: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const {
                    data: { news },
                } = await get<void, { data: { news: BrandNews } }>(`/v1/web/brands/news/${id}`)

                return news
            } catch (err) {
                root.error.prepare(err)
            }
        },

        deleteNews: async (id: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const data = await del<void>(`/v1/web/brands/news/${id}`)

                // TODO
                return { todo: true }
            } catch (err) {
                root.error.prepare(err)
            }
        },

        createNews: async (
            brandId: string,
            data: {
                title: string
                teaserText: string
                bodyMessage: string
                color: string
                pdf: FileList | null
                dismissible: boolean
                startDate: Date
                endDate: Date
            }
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                let pdfFile: string

                if (data.pdf) {
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    let file: File | any = data.pdf[0]

                    if (file) {
                        const {
                            data: { config },
                        } = await get<
                            void,
                            {
                                data: {
                                    config: {
                                        bucketName: string
                                        region: string
                                        accessKeyId: string
                                        secretAccessKey: string
                                        dirName: string
                                    }
                                }
                            }
                        >(`/v1/web/brands/${brandId}/config`)

                        const { accessKeyId, secretAccessKey, bucketName, region, dirName } = config

                        const client = new S3({
                            region,
                            credentials: {
                                accessKeyId,
                                secretAccessKey,
                            },
                        })
                        const fileType = file.type
                        const buffer = await file.arrayBuffer()
                        const filename = `${uuidv4()}_${slugify(file.name, { lower: true })}.gz`
                        pdfFile = `${dirName}/${filename}`
                        const fileAsArray = new Uint8Array(buffer)
                        file = pako.gzip(fileAsArray, { level: 9 })

                        try {
                            const upload = new Upload({
                                client,
                                queueSize: 3,
                                params: {
                                    Bucket: bucketName,
                                    Key: pdfFile,
                                    Body: Blob.prototype.stream ? file : safariStream(file),
                                    ACL: 'public-read',
                                    ContentType: fileType,
                                    ContentEncoding: 'gzip',
                                },
                            })

                            await upload.done()
                        } catch (error) {
                            console.error({ error, message: error.message })
                            throw new Error('web_upload_error')
                        }
                    }
                }

                const postData = { ...data, file: pdfFile }

                const { news } = await post<typeof postData, { news: BrandNews }>('/v1/web/brands/news', postData)

                return news
            } catch (err) {
                root.error.prepare(err)
            }
        },

        updateNews: async (
            brandId: string,
            id: string,
            data: {
                title: string
                teaserText: string
                bodyMessage: string
                color: string
                pdf: FileList | null
                dismissible: boolean
                startDate: Date
                endDate: Date
            }
        ) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                let pdfFile: string

                if (data.pdf) {
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    let file: File | any = data.pdf[0]

                    if (file) {
                        const {
                            data: { config },
                        } = await get<
                            void,
                            {
                                data: {
                                    config: {
                                        bucketName: string
                                        region: string
                                        accessKeyId: string
                                        secretAccessKey: string
                                        dirName: string
                                    }
                                }
                            }
                        >(`/v1/web/brands/${brandId}/config`)

                        const { accessKeyId, secretAccessKey, bucketName, region, dirName } = config

                        const client = new S3({
                            region,
                            credentials: {
                                accessKeyId,
                                secretAccessKey,
                            },
                        })
                        const fileType = file.type
                        const buffer = await file.arrayBuffer()
                        const filename = `${uuidv4()}_${slugify(file.name, { lower: true })}.gz`
                        pdfFile = `${dirName}/${filename}`
                        const fileAsArray = new Uint8Array(buffer)
                        file = pako.gzip(fileAsArray, { level: 9 })

                        try {
                            const upload = new Upload({
                                client,
                                queueSize: 3,
                                params: {
                                    Bucket: bucketName,
                                    Key: pdfFile,
                                    Body: Blob.prototype.stream ? file : safariStream(file),
                                    ACL: 'public-read',
                                    ContentType: fileType,
                                    ContentEncoding: 'gzip',
                                },
                            })

                            await upload.done()
                        } catch (error) {
                            console.error({ error, message: error.message })
                            throw new Error('web_upload_error')
                        }
                    }
                }

                const putData = { ...data, file: pdfFile }
                const { news } = await put<typeof putData, { news: BrandNews }>(`/v1/web/brands/news/${id}`, putData)

                return news
            } catch (err) {
                root.error.prepare(err)
            }
        },

        loadTags: async (id: string, q?: string) => {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const getData = {
                    q,
                }

                const data = await get<typeof getData, { data: { tags: { uuid: string; name: string }[] } }>(
                    `/v1/web/brands/${id}/tags`,
                    getData
                )
                const {
                    data: { tags },
                } = data

                return tags
            } catch (error) {
                console.error(error)
            }
        },

        createTag: flow(function* (id: string, name: string) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            try {
                const postData = {
                    name,
                }

                const data = yield post<typeof postData, { data: { tag: { uuid: string; name: string } } }>(
                    `/v1/web/brands/${id}/tags`,
                    postData
                )
                const {
                    data: { tag },
                } = data

                return tag
            } catch (error) {
                console.error(error)
            }
        }),
    }))

export interface Brand extends Instance<typeof _Brand> {}

import { mapOrJsonOptions } from 'core/utils/map-to-object'
import { DateTime } from 'luxon'
import { toJS } from 'mobx'
import { flow, getRoot, Instance, types } from 'mobx-state-tree'
import { _AnyJsonValue } from 'stores/any'
import { User } from 'stores/users'
import Config from '../../core/config'
import { post, put } from '../../core/services/http-service'
import { RootInstance } from '../store'
import { _SpaceDirectory } from './directory'
import { _Space, Space } from './space'
import { _FilenameSuggestion, FilenameSuggestion, FileStatus, SuggestionTag } from './suggestion'

export const _GuestFile = types
    .model('_GuestFile', {
        uuid: '',
        name: '',
        path: types.optional(types.string, '', [null, undefined]),
        size: 0,
        dateAdded: 0,
        lastModified: 0,
    })
    .views(self => ({
        get extension(): string | undefined {
            if (self.name.indexOf('.') === -1) {
                return undefined
            }

            return self.name.split(/\./).reverse().shift()?.toLocaleLowerCase()
        },

        get createdAt(): DateTime {
            return DateTime.fromMillis(self.dateAdded)
        },
    }))
export interface GuestFile extends Instance<typeof _GuestFile> {}

export const _GuestDirectory = types.model('_GuestDirectory', {
    uuid: '',
    name: '',
    directories: types.array(types.late(() => _GuestDirectory)),
    files: types.array(types.late(() => _GuestFile)),
})
export interface GuestDirectory extends Instance<typeof _GuestDirectory> {}

export const _GuestSpace = types.model('_GuestSpace', {
    uuid: '',
    name: '',
    color: '',
    spaces: types.array(types.late(() => _GuestSpace)),
    directory: types.array(types.late(() => _GuestDirectory)),
})
export interface GuestSpace extends Instance<typeof _GuestSpace> {}

export const _GuestSharingFile = types.model('_GuestSharingFile', {
    uuid: '',
    file: types.optional(
        types.late(() => _GuestFile),
        {},
        [null, undefined]
    ),
    directory: types.optional(
        types.late(() => _GuestDirectory),
        {},
        [null, undefined]
    ),
    space: types.optional(
        types.late(() => _GuestSpace),
        {},
        [null, undefined]
    ),
})
export interface GuestSharingFile extends Instance<typeof _GuestSharingFile> {}

export const _GuestPartner = types.model('_GuestPartner', {
    name: '',
    logo: '',
    color: '',
    viaColor: '',
    brandImage: '',
    brandLogo: '',
})
export interface GuestPartner extends Instance<typeof _GuestPartner> {}

export const _GuestSharing = types.model('_GuestSharing', {
    uuid: '',
    name: '',
    archive: types.optional(types.string, '', [null, undefined]),
    archiveName: types.optional(types.string, '', [null, undefined]),
    sharedBy: '',
    size: 0,
    fileCount: 0,
    files: types.array(types.late(() => _GuestSharingFile)),
    partner: types.optional(
        types.late(() => _GuestPartner),
        {},
        [null, undefined]
    ),
    validTo: 0,
})
export interface GuestSharing extends Instance<typeof _GuestSharing> {}

export const _OcrStatus = types.model('OcrStatus', {
    jobId: '',
    status: '',
})
export interface OcrStatus extends Instance<typeof _OcrStatus> {}

export const _SpaceFile = types
    .model('SpaceFile', {
        uuid: '',
        userUuid: types.optional(types.string, '', [null, undefined]),
        name: '',
        origName: '',
        hash: '',
        size: 0,
        origSize: 0,
        compressed: false,
        dateAdded: 0,
        lastModified: 0,
        dbCreatedAt: 0,
        dbUpdatedAt: 0,
        storage: '',
        amazonPath: '',
        options: types.optional(_AnyJsonValue, {}, [null, undefined]),
        ocr: types.optional(
            types.late(() => _OcrStatus),
            {},
            [null, undefined]
        ),
        thumb: types.optional(types.string, '', [null, undefined]),
        /* eslint-disable @typescript-eslint/no-use-before-define */
        space: types.optional(
            types.late(() => _Space),
            {}
        ),
        directory: types.optional(
            types.late(() => _SpaceDirectory),
            {}
        ),
        /* eslint-enable @typescript-eslint/no-use-before-define */
        sharableLink: '',
        directoryUuid: '',
        spaceUuid: '',
        suggestion: types.optional(
            types.late(() => _FilenameSuggestion),
            {}
        ),
        isDeletable: true,
        isEditable: true,
        documentTypeId: types.optional(types.string, '', [null, undefined]),
        documentTypeName: types.optional(types.string, '', [null, undefined]),
        isComplianceDocument: types.optional(types.boolean, false, [null, undefined]),
        documentTypeRequired: types.optional(types.boolean, false, [null, undefined]),
        sharePointLink: types.optional(types.string, '', [null, undefined]),
    })
    .volatile<{ rhUser?: string }>(() => ({
        rhUser: undefined,
    }))
    .views(self => ({
        get jsonOptions(): Record<string, unknown> {
            return mapOrJsonOptions(self.options)
        },
        get isDirectory() {
            return false
        },
        get isFile() {
            return true
        },
        get isSpace() {
            return false
        },
        get isConnector() {
            return false
        },
        get storeStatus() {
            if (!self.suggestion) {
                return null
            }

            if (![FileStatus.loading, FileStatus.to_sort].includes(self.suggestion.status)) {
                return self.suggestion.status
            }

            return null
        },
        get displayName(): string {
            return self.name
        },
        get filename(): string {
            return self.name
        },
        get origFilename(): string {
            return self.origName
        },
        get createdAt(): DateTime {
            return DateTime.fromMillis(self.dateAdded)
        },
        get updatedAt(): DateTime {
            return DateTime.fromMillis(self.lastModified)
        },
        get creationDate(): DateTime {
            return DateTime.fromMillis(self.dbCreatedAt)
        },
        get updateDate(): DateTime {
            return DateTime.fromMillis(self.dbUpdatedAt)
        },

        get extension(): string | undefined {
            if (self.name.indexOf('.') === -1) {
                return undefined
            }

            return self.name.split(/\./).reverse().shift()?.toLocaleLowerCase()
        },

        get isImage(): boolean {
            if (!this.extension) {
                return false
            }

            return ['jpg', 'jpeg', 'png', 'webp'].includes(this.extension)
        },

        imageRepresentation(size: number): string | undefined {
            if (self.thumb !== '' || this.isImage) {
                const root = getRoot(self) as RootInstance
                const token = root.token
                const path = self.thumb !== '' ? 'thumb' : 'files'
                if (self.storage === 'amazon') {
                    return self.thumb === '' ? self.amazonPath : self.thumb
                }

                const params = new URLSearchParams({
                    method: 'cover',
                    w: `${size}`,
                    h: `${size}`,
                })
                if (root.user?.currentFranchise?.uuid) {
                    params.append('cf', root.user?.currentFranchise?.uuid)
                }

                return `${Config.app.APIURL}/v1/web/${path}/${token}/${self.uuid}?${params.toString()}`
            }

            return undefined
        },

        get hasImage(): boolean {
            return this.isImage || self.thumb !== ''
        },

        get downloadUrl(): string {
            const root = getRoot(self) as RootInstance
            const token = root.token

            const params = new URLSearchParams()
            if (root.user?.currentFranchise?.uuid) {
                params.append('cf', root.user?.currentFranchise?.uuid)
            }

            return `${Config.app.APIURL}/v1/web/files/${token}/${self.uuid}?${params.toString()}`
        },

        get topSpace(): Space | undefined {
            const root = getRoot(self) as RootInstance

            return root.files.recursivelyGetSpaceParent(self.spaceUuid)
        },

        get directoryName(): string {
            const directory = this.getDirectory()

            return directory?.displayName ?? ''
        },
        get parentUuid() {
            const directory = this.getDirectory()
            if (directory?.name === '/') {
                return directory.spaceUuid
            }

            return self.directoryUuid
        },
    }))
    .views(self => ({
        getDirectory() {
            const root = getRoot(self) as RootInstance
            const directory = root.files.recursivelyFindDirectory(self.directoryUuid)

            return directory
        },

        get connector() {
            const directory = this.getDirectory()
            if (!directory) {
                return undefined
            }

            return directory.connector
        },
        get subConnector() {
            const connector = this.connector

            return connector !== undefined
        },
        get connectorUuid() {
            const directory = this.getDirectory()
            if (!directory) {
                return undefined
            }

            return directory.connectorUuid
        },
        get subscriber() {
            const directory = this.getDirectory()
            if (!directory) {
                return undefined
            }

            return directory.subscriber
        },
        get label() {
            const directory = this.getDirectory()
            if (!directory) {
                return undefined
            }

            return directory.label
        },

        get spaceName(): string {
            return this.toSpace?.displayName ?? ''
        },

        isEditableBy(user: User): boolean {
            if (!self.isEditable) {
                return false
            }

            const topSpace = this.topSpace
            if (!topSpace) {
                return false
            }

            if (topSpace.jsonOptions && topSpace.jsonOptions.internalBrandDirectory) {
                return user.uuid === self.userUuid
            }

            return true
        },

        isDeletableBy(user: User): boolean {
            if (!self.isDeletable) {
                return false
            }

            const topSpace = this.topSpace
            if (!topSpace) {
                return false
            }

            if (topSpace.jsonOptions && topSpace.jsonOptions.internalBrandDirectory) {
                return user.uuid === self.userUuid
            }

            return true
        },
    }))
    .actions(self => ({
        setRhUser: (rhUser: string) => {
            self.rhUser = rhUser
        },

        setSuggestion: (suggestion: FilenameSuggestion) => {
            self.suggestion = suggestion
        },

        refresh: flow(function* () {
            const directory = self.getDirectory()
            if (directory) {
                yield directory.refresh()
            }
        }),

        rename: flow(function* (
            name: string,
            tags: SuggestionTag[] | null,
            separator: string | null,
            transform: string | null
        ) {
            const root = getRoot(self) as RootInstance
            root.error.clean()

            const postData = {
                displayFilename: name,
                tags,
                separator,
                transform,
            }
            interface JsonFile {}
            interface JsonData {
                file: JsonFile
            }
            interface JsonReturn {
                data: JsonData
            }

            try {
                const data = yield put<typeof postData, JsonReturn>(`/v1/web/files/${self.uuid}`, postData)

                const {
                    data: { file, collaborationData },
                } = data

                file.suggestion = undefined
                Object.assign(self, file)

                if (collaborationData) {
                    const notifyData = {
                        type: 'renamed_objects',
                        data: {
                            movedObjects: [
                                {
                                    object: {
                                        uuid: file.uuid,
                                        name: file.displayName,
                                    },
                                    collaborationData,
                                },
                            ],
                        },
                    }
                    yield post<typeof notifyData, void>('/v1/web/notifications/event', notifyData)
                }
            } catch (err) {
                root.error.prepare(err)
            }
        }),
    }))
export interface SpaceFile extends Instance<typeof _SpaceFile> {}

export interface LoginButtonProps {
    provider: Provider
}

export type MailCollectType = 'ALL' | 'MAIL_CONTENT' | 'MAIL_ATTACHMENTS' | 'NONE'

export type SubscriptionType = 'MAILBOX' | 'SHAREPOINT_LIST' | 'ONE_DRIVE'

export enum ProviderReference {
    GOOGLE = 'google',
    MICROSOFT = 'microsoft',
}

export interface Provider {
    uuid: string
    reference: ProviderReference
    name: string
    loginUrl?: string
}

export interface SharePointSite {
    uuid: string
    id: string
    name: string
    url: string
    lists: SharePointList[]
}

export interface SharePointList {
    uuid: string
    id: string
    name: string
    url: string
    hasSubscription: boolean
}

export type Account = {
    uuid: string
    email: string
    mailboxLinked: boolean
    mailCollectType: MailCollectType
    provider: Provider
    sharePointSites: SharePointSite[]
} & ({ oneDriveLinked: true; oneDrive: { url: string } } | { oneDriveLinked: false; oneDrive: undefined })

export type ListItem = { id: string; name: string; url: string; children: ListItem[] }
export type SiteLight = Pick<SharePointSite, 'id' | 'name' | 'url'> & { lists: ListLight[]; sites: SiteLight[] }
export type ListLight = Pick<SharePointList, 'id' | 'name' | 'url'> & { items: ListItem[] }

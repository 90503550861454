import { faCaretRight, faDownload, faLightbulbOn, faPen, faPencil, faShuffle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Ocr from 'assets/dataroom/ocr'
import { ReactComponent as Compressed } from 'assets/leaf.svg'
import { clsx } from 'clsx'
import { Input } from 'components/shared/select-styles'
import Tooltip from 'components/shared/tooltip'
import { get } from 'core/services/http-service'
import { DateTime } from 'luxon'
import { toJS, values } from 'mobx'
import { observer } from 'mobx-react-lite'
import { isAlive } from 'mobx-state-tree'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Element, scroller } from 'react-scroll'
import AsyncSelect from 'react-select/async'
import type { SpaceDirectory } from 'stores/files/directory'
import type { SpaceFile } from 'stores/files/file'
import type { Space } from 'stores/files/space'
import type { FileStatus } from 'stores/files/suggestion'
import { useMst } from 'stores/store'
import Editable from '../../shared/editable'
import MoveFile from '../move-file'
import { fileColors } from '../upload/file-colors'
import FileIcon from '../upload/file-icon'
import { getFileSize } from '../upload/file-size'
import DownloadLink from './download-link'
import { Line } from './line'
import { type FileOrDirectoryProps, padding } from './props'
import { Row } from './row'
import { checkboxColor } from './space'
import Config from 'core/config'
import { ReactComponent as SharePoint } from '../../../assets/sharepoint.svg'

interface FileProps extends FileOrDirectoryProps {
    file: SpaceFile
    space: Space
    directory?: SpaceDirectory
    onSelect: (file: SpaceFile | SpaceDirectory | Space) => void
    onDeselect: (file: SpaceFile | SpaceDirectory | Space) => void
    highlightedFile?: string
    onRenameSuggestionClicked: (file: SpaceFile) => void
    canOfferSuggestion: boolean
}

export const FileImage = ({ file, filename, className }: { file: SpaceFile; filename: string; className?: string }) => {
    const [hasImage, setHasImage] = useState<boolean>()

    if (!hasImage) {
        return <FileIcon extension={file.extension} className={clsx('w-5 shrink-0 grow-0', className)} />
    }

    return (
        <img
            src={file.imageRepresentation(25)}
            loading="lazy"
            alt={filename}
            className={clsx('max-h-[1.25rem] w-5 max-w-[1.25rem] shrink-0 grow-0', className)}
            onError={() => setHasImage(false)}
        />
    )
}

const colorStatuses: Record<FileStatus, string> = {
    loading: 'bg-mercury',
    to_sort: 'bg-seagull',
    pre_sorted: 'bg-portage',
    dupe: 'bg-atomic-tangerine',
    quarantine: 'bg-bittersweet',
    trash: 'bg-thunder',
    sorted: 'bg-sushi',
    to_be_validated: 'bg-christine',
}

export const baseClassNames = 'border-b border-b-mercury transition-all duration-300 ease-linear px-2 h-8'
export const checkboxClassNames =
    'border-geyser w-4 h-4 rounded-none checked:border-christine group-hover:border-christine'

export const File = observer(
    ({
        file,
        space,
        directory,
        level,
        selected,
        canChangeSelection,
        onDeselect,
        onSelect,
        highlightedFile,
        onRenameSuggestionClicked,
        onSetFileDocumentTypeClicked,
        canOfferSuggestion,
        isRefreshing,
    }: FileProps) => {
        const { t } = useTranslation()
        const { files, user } = useMst()

        const [editFilename, setEditFilename] = useState<boolean>(false)
        const [filename, setFilename] = useState<string>(file.name)
        const [showSpaces, setShowSpaces] = useState<boolean>(false)
        const [spaces, setSpaces] = useState<Space[]>([])
        const [hasSuggestions, setHasSuggestions] = useState<boolean>(false)
        const [isOver, setOver] = useState<boolean>(false)
        const [checked, setChecked] = useState<boolean>(false)
        const [disabled, setDisabled] = useState<boolean>(false)
        const [rhUser, setRhUser] = useState<{ value: string; label: string }>()

        const changeSelection = (newValue: boolean) => {
            newValue ? onSelect(file) : onDeselect(file)
            setChecked(newValue)
        }

        const toggleChecked = () => {
            const newValue = !checked
            changeSelection(newValue)
        }
        useEffect(() => {
            setChecked(selected)
            setDisabled(!canChangeSelection || isRefreshing)
        }, [selected, canChangeSelection, isRefreshing])

        useEffect(() => {
            if (files.ocrFileRenamed?.uuid === file.uuid) {
                const refresh = async () => {
                    setFilename(files.ocrFileRenamed?.name ?? file.name)
                    await file.refresh()
                    await files.getFilenameSuggestions(file)
                    files.setOcrFileRenamed(undefined)
                }
                refresh()
            }
        }, [files.ocrFileRenamed])

        const onSave = async (filename: string) => {
            setFilename(filename)
            await file.rename(filename)
            setEditFilename(false)
        }

        const getRenameSuggestions = async (file: SpaceFile) => {
            await files.getFilenameSuggestions(file)
            onRenameSuggestionClicked(file)
        }

        const setChooseDirectory = (space: Space | undefined, directory: SpaceDirectory | undefined) => {
            if (!space) {
                return
            }
            file.suggestion.setSpace(space)
            file.suggestion.setDirectory(directory)

            setShowSpaces(false)
            changeSelection(true)
        }

        const getFilenameSuggestions = async () => {
            await files.getFilenameSuggestions(file)
            setHasSuggestions(file.suggestion.suggestions.length > 1)
        }

        const selectRhUser = (rhUser: { value: string; label: string }) => {
            setRhUser(rhUser)
            file.setRhUser(rhUser.value)
        }

        const findRhUser = async (filename: string) => {
            const brand = user.currentBrand
            if (!brand) {
                return
            }
            const pattern =
                space.jsonOptions?.rh?.filePattern ?? '(?<firstname>[\\p{Letter}]+) (?<lastname>[\\p{Letter}]+)\\.pdf'
            const regex = new RegExp(pattern, 'gu')
            const matches = regex.exec(filename)
            if (matches?.groups) {
                const { firstname, lastname } = matches.groups
                if (!firstname || !lastname) {
                    return
                }

                const {
                    data: { users },
                } = await get<
                    { search: string[] },
                    { data: { users: { uuid: string; fullname: string; email: string }[] } }
                >(`/v1/web/brands/${brand.uuid}/users`, {
                    search: [`${firstname} ${lastname}`, `${lastname} ${firstname}`],
                })

                const [rhUser] = users
                if (rhUser) {
                    selectRhUser({ value: rhUser.uuid, label: `${rhUser.fullname} (${rhUser.email})` })
                }
            }
        }

        useEffect(() => {
            if (!file || !isAlive(file)) {
                return
            }
            if (file.suggestion && file.suggestion.filename !== '') {
                setHasSuggestions(file.suggestion.suggestions.length > 1)

                return
            }
            getFilenameSuggestions()
        }, [file])

        useEffect(() => {
            if (highlightedFile === file.uuid) {
                scroller.scrollTo(highlightedFile, {
                    duration: 100,
                    delay: 100,
                    smooth: true,
                    offset: -250,
                })
            }
        }, [highlightedFile])

        useEffect(() => {
            setFilename(file.name)
            if (space.jsonOptions?.rh) {
                findRhUser(file.name)
            }
        }, [file.name])

        useEffect(() => {
            const load = async () => {
                await files.getFiles()
                setSpaces(
                    values<Space>(files.spaces).filter(
                        space => !space.isStack && !space.isQuarantine && space.parent === ''
                    )
                )

                if (!file.isAlive) {
                    return
                }
                await files.getFilenameSuggestions(file)
            }

            if (canOfferSuggestion) {
                load()
            }
        }, [canOfferSuggestion])

        return (
            <Line fileOrDirectory={file}>
                {user.canManagePersonalData && (
                    <Row
                        highlight={highlightedFile === file.uuid}
                        className={clsx(
                            'group flex items-center justify-center border-l-2 border-christine',
                            checkboxColor(isOver, checked, canChangeSelection),
                            baseClassNames
                        )}
                        onMouseEnter={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                    >
                        <input
                            type="checkbox"
                            checked={checked}
                            disabled={disabled}
                            onChange={() => toggleChecked()}
                            className={checkboxClassNames}
                        />
                    </Row>
                )}
                <Row
                    highlight={highlightedFile === file.uuid}
                    className={clsx(
                        'flex items-center justify-between gap-3 py-1 pr-1',
                        baseClassNames.replace('px-2', '')
                    )}
                    style={{
                        paddingLeft: `${padding(level)}rem`,
                        gridColumn: canOfferSuggestion && file.suggestion ? 'span 7 / span 7' : 'span 15 / span 15',
                    }}
                    onMouseEnter={() => setOver(true)}
                    onMouseLeave={() => setOver(false)}
                >
                    <Element name={file.uuid}>
                        <FileImage file={file} filename={filename} />
                    </Element>

                    <Editable
                        isFile
                        isEditing={editFilename}
                        label={filename}
                        onSave={filename => onSave(filename)}
                        onCancel={() => setEditFilename(false)}
                    >
                        <div className="grow flex gap-4 items-center">
                            <DownloadLink href={file.downloadUrl} className="truncate text-inner-space">
                                {filename}
                            </DownloadLink>

                            {Config.app.VOUSFINANCER_V3_ENABLED &&
                                directory.checklist.uuid.length > 0 &&
                                directory.clientUuid.length > 0 &&
                                file.documentTypeRequired && (
                                    <div className="flex gap-2 items-center">
                                        <span className="text-pale-sky text-sm">
                                            <span>[{t('web_checklist_document_type')}:</span>
                                            <span className="ml-1">
                                                {file.documentTypeName.length > 0 ? file.documentTypeName : '-'}]
                                            </span>
                                        </span>
                                        {file.documentTypeName.length === 0 && (
                                            <Tooltip tooltip={t('web_checklist_document_type_set')}>
                                                <button
                                                    type="button"
                                                    onClick={() => onSetFileDocumentTypeClicked?.(file)}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faShuffle}
                                                        fixedWidth
                                                        size="sm"
                                                        className="shrink-0 grow-0 cursor-pointer fill-current text-pale-sky"
                                                    />
                                                </button>
                                            </Tooltip>
                                        )}
                                    </div>
                                )}
                        </div>

                        {file.ocr && file.ocr.jobId !== '' && file.ocr.status !== '' && (
                            <Tooltip tooltip={t(`web_tooltips_dataroom_ocr_${file.ocr.status}`)}>
                                <span>
                                    <Ocr className="w-5 shrink-0 grow-0 cursor-help text-christine" />
                                </span>
                            </Tooltip>
                        )}

                        {file.sharePointLink.length > 0 && (
                            <Tooltip tooltip={t('web_tooltips_go_sharepoint')}>
                                <a href={file.sharePointLink} target="_blank" rel="noreferrer">
                                    <SharePoint className="w-6 h-6" />
                                </a>
                            </Tooltip>
                        )}

                        <Tooltip tooltip={t('web_tooltips_dataroom_download')}>
                            <a href={`${file.downloadUrl}&download=1`} className="" download={true}>
                                <FontAwesomeIcon icon={faDownload} className="text-pale-sky" />
                            </a>
                        </Tooltip>

                        {space.jsonOptions?.kind === 'rh' && space.jsonOptions?.rh ? (
                            <>
                                <AsyncSelect
                                    styles={{
                                        control: (
                                            provided: Record<string, string | number>,
                                            state: unknown
                                        ): Record<string, string | number> => ({
                                            ...provided,
                                            borderColor: '#D8E0E6',
                                            borderRadius: 0,
                                            borderTop: 0,
                                            borderBottom: 0,
                                            padding: 0,
                                            maxHeight: '30px',
                                            minHeight: '30px',
                                        }),
                                        input: (
                                            provided: Record<string, string | number>,
                                            state: unknown
                                        ): Record<string, string | number> => ({
                                            ...provided,
                                            padding: 0,
                                        }),
                                        valueContainer: (
                                            provided: Record<string, string | number>,
                                            state: unknown
                                        ): Record<string, string | number> => ({
                                            ...provided,
                                            padding: '0 8px',
                                        }),
                                        dropdownIndicator: (
                                            provided: Record<string, string | number>,
                                            state: unknown
                                        ): Record<string, string | number> => ({
                                            ...provided,
                                            padding: 4,
                                        }),
                                    }}
                                    components={{ Input }}
                                    className="w-1/3"
                                    value={rhUser}
                                    isClearable
                                    defaultOptions
                                    placeholder={t('web_admin_placeholder_select')}
                                    loadOptions={async (inputValue: string) => {
                                        const brand = user.currentBrand
                                        if (!brand) {
                                            return []
                                        }

                                        const {
                                            data: { users },
                                        } = await get<
                                            { search: string },
                                            { data: { users: { uuid: string; fullname: string; email: string }[] } }
                                        >(`/v1/web/brands/${brand.uuid}/users`, { search: inputValue })

                                        return users.map(u => ({
                                            value: u.uuid,
                                            label: `${u.fullname} (${u.email})`,
                                        }))
                                    }}
                                    onChange={user => selectRhUser(user)}
                                />
                            </>
                        ) : (
                            file.isEditableBy(user) && (
                                <>
                                    <Tooltip tooltip={t('web_tooltips_dataroom_rename')}>
                                        <FontAwesomeIcon
                                            icon={faPen}
                                            fixedWidth
                                            size="sm"
                                            className="shrink-0 grow-0 cursor-pointer fill-current text-pale-sky"
                                            onClick={() => setEditFilename(true)}
                                        />
                                    </Tooltip>
                                    {hasSuggestions && (
                                        <Tooltip tooltip={t('web_tooltips_dataroom_process')}>
                                            <FontAwesomeIcon
                                                icon={faLightbulbOn}
                                                fixedWidth
                                                size="sm"
                                                className="w-5 shrink-0 grow-0 cursor-pointer text-macaroni-and-cheese"
                                                onClick={() => getRenameSuggestions(file)}
                                            />
                                        </Tooltip>
                                    )}
                                </>
                            )
                        )}
                    </Editable>
                </Row>
                {canOfferSuggestion && file.suggestion && (
                    <Row
                        highlight={highlightedFile === file.uuid}
                        className={clsx('col-span-8 flex gap-2 py-0.5', baseClassNames)}
                        onMouseEnter={() => setOver(true)}
                        onMouseLeave={() => setOver(false)}
                    >
                        {file.isEditableBy(user) && (
                            <>
                                <div
                                    className={clsx(
                                        'flex shrink-0 grow-0 items-center justify-center rounded px-2 text-sm font-bold text-white',
                                        colorStatuses[file.suggestion.status]
                                    )}
                                >
                                    {t(`web_dataroom_upload_status_${file.suggestion.status}`)}
                                </div>
                                <span
                                    className="relative flex shrink grow cursor-pointer items-center justify-between truncate rounded bg-black-squeeze px-4 text-sm font-bold"
                                    onClick={() => setShowSpaces(true)}
                                >
                                    <span className="truncate">&nbsp;{file.suggestion.spaceAndPath}</span>
                                    <FontAwesomeIcon
                                        icon={faCaretRight}
                                        className="mr-2 fill-current text-regent-gray"
                                    />
                                </span>
                            </>
                        )}
                    </Row>
                )}
                <Row
                    highlight={highlightedFile === file.uuid}
                    className={clsx('col-span-2 flex items-center justify-center', baseClassNames)}
                    onMouseEnter={() => setOver(true)}
                    onMouseLeave={() => setOver(false)}
                >
                    <Tooltip tooltip={file.createdAt.toLocaleString(DateTime.DATETIME_MED)}>
                        <div>
                            <span className="hidden xl:block">
                                {file.createdAt.toLocaleString(DateTime.DATE_SHORT)}
                            </span>
                            <span className="xl:hidden">{file.createdAt.year}</span>
                        </div>
                    </Tooltip>
                </Row>
                <Row
                    highlight={highlightedFile === file.uuid}
                    className={clsx('col-span-2 flex items-center justify-center', baseClassNames)}
                    onMouseEnter={() => setOver(true)}
                    onMouseLeave={() => setOver(false)}
                >
                    <span className="hidden xl:block">
                        {file.extension && Object.keys(fileColors).includes(file.extension)
                            ? t('web_space_file_type', { type: file.extension })
                            : t('web_space_file')}
                    </span>
                    <span className="xl:hidden">{file.extension}</span>
                </Row>
                <Row
                    highlight={highlightedFile === file.uuid}
                    className={clsx('col-span-2 flex items-center justify-center', baseClassNames)}
                    onMouseEnter={() => setOver(true)}
                    onMouseLeave={() => setOver(false)}
                >
                    <div className="flex items-center justify-between space-x-2">
                        {file.compressed ? (
                            <Tooltip
                                tooltip={t('web_compressed_gain_size', {
                                    size: getFileSize(t, file.size),
                                    origSize: getFileSize(t, file.origSize),
                                })}
                            >
                                <Compressed className="cursor-help" />
                            </Tooltip>
                        ) : (
                            <span>&nbsp;</span>
                        )}
                        <span>{getFileSize(t, file.size)}</span>
                    </div>
                </Row>

                <MoveFile
                    isVisible={showSpaces}
                    showDirectories={true}
                    spaces={spaces}
                    selection={[
                        {
                            displayName: file.displayName,
                            uuid: file.uuid,
                            isFile: true,
                            isDirectory: false,
                            isSpace: false,
                            isEditable: file.isEditableBy(user),
                            isDeletable: file.isDeletableBy(user),
                            parentUuid: file.parentUuid,
                        },
                    ]}
                    onClose={() => setShowSpaces(false)}
                    onChoose={(space, directory) => setChooseDirectory(space, directory)}
                />
            </Line>
        )
    }
)

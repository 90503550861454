import ToggleButton from 'components/shared/toggle-button'
import type { Account, SharePointSite, SharePointList as T } from '../types'
import toast from 'core/utils/toast'
import { put } from 'core/services/http-service'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'

function SharePointList({
    account,
    site,
    list,
    onListSubscription,
}: { account: Account; site: SharePointSite; list: T; onListSubscription: () => void }) {
    const { t } = useTranslation()

    const [enabled, setEnabled] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    async function changeListSubscribe(accountId: string, siteId: string, listId: string) {
        setLoading(true)

        const { data } = await put<
            undefined,
            {
                data: { success: true; message: undefined; subscribed: boolean } | { success: false; message: string }
            }
        >(`/v1/web/microsoft/accounts/${accountId}/sharepoint/sites/${siteId}/lists/${listId}`)

        if (data.success) {
            toast('success', t('web_me_options_sharepoint_saved'))
            setEnabled(!enabled)

            if (data.subscribed) {
                onListSubscription()
            }
        } else {
            toast('error', data.message)
        }

        setLoading(false)
    }

    useEffect(() => {
        setEnabled(list.hasSubscription)
    }, [list])

    return (
        <div key={list.id} className="flex gap-2 items-center relative">
            <div className="relative">
                {loading && (
                    <div className="absolute z-10 bg-white/50 inset-0 flex items-center justify-center">
                        <FontAwesomeIcon icon={faSpinner} spin className="text-christine h-4 w-4" />
                    </div>
                )}
                <ToggleButton
                    value={enabled}
                    onChange={async () => await changeListSubscribe(account.uuid, site.uuid, list.uuid)}
                />
            </div>
            <span>
                <button
                    type="button"
                    disabled={loading}
                    onClick={async () => await changeListSubscribe(account.uuid, site.uuid, list.uuid)}
                >
                    {list.name}
                </button>
                <span className="ml-1">
                    (
                    <a href={list.url} target="_blank" rel="noreferrer">
                        {list.url}
                    </a>
                    )
                </span>
            </span>
        </div>
    )
}

export default SharePointList
